import React, { useState, useEffect, useRef, useMemo, useReducer } from "react";
import { Grid, Col, Row, Table } from "react-bootstrap";
import DeviceList from "components/Devices/DeviceList.jsx";
import SelectedDevice from "components/Devices/SelectedDevice.jsx";

import SearchBar from "components/SearchBar/SearchBar.jsx";
import Card from "components/Card/Card.jsx";
import MenuButton from "components/MenuButton/MenuButton.jsx";

import BrowserHistory from "models/BrowserHistory";
import DeviceMap from "components/Devices/DeviceMap";


const UserMgmt = ({ api }) => {
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [usersInfo, setUsers] = useState({id: 0, managableUsers: [], uninit: true});
  if(usersInfo.uninit) {
    usersInfo.uninit = false;
    setUsers(usersInfo);
    api.fetch_managable_users().then(setUsers);
  }

  return (
    <div className="main-content">
        <Card
        content={
            <>
            <button onClick={(e)=>api.fetch_managable_users().then(setUsers)}>Refresh</button>
            <Table bordered>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Last Seen</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {usersInfo.managableUsers.length === 0 ? (
                    <tr>
                        <td rowSpan="5">
                            <small style={{ color: "white" }}>---</small>
                        </td>
                    </tr>
                ) : (
                    usersInfo.managableUsers.map((u) => (
                        <tr key={u.id}>
                        <td>{u.id}</td>
                        <td style={u.isDisabled ? {"color": "#666", "textDecoration": "line-through"} : {}}>{u.name}</td>
                        <td style={u.isDisabled ? {"color": "#666", "textDecoration": "line-through"} : {}}>{u.email}</td>
                        <td>{u.lastSeen}</td>
                        <td>
                            {u.id != usersInfo.id ? (u.isDisabled ?
                            <button onClick={(e) => { u.do_enable(); forceUpdate(); }}>Enable</button> :
                            <button onClick={(e) => { u.do_disable(); forceUpdate(); }}>Disable</button>) : <></>}
                        </td>
                        </tr>
                    ))
                )}
                </tbody>
            </Table>
            </>
        }
        />
    </div>
  );
};

export default UserMgmt;
