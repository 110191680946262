/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Dashboard from "views/Dashboard.jsx";
import UserMgmt from "views/UserMgmt.jsx";
//import Charts from "views/Charts.jsx";
import ToDo from "views/ToDo.jsx"

var routes = [
  {
    path: "/dashboard",
    layout: "/admin",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard
  },
  {
    path: "/users",
    layout: "/admin",
    name: "Users",
    icon: "pe-7s-users",
    component: UserMgmt,
  },
  /*{
    path: "/reporting",
    layout: "/admin",
    name: "Reports",
    icon: "pe-7s-graph2",
    component: Charts
  },*/
	{
    path: "/jobs",
    layout: "/admin",
    name: "Jobs",
    icon: "pe-7s-graph2",
    content: (<ToDo
			title="Job Management"
			list={[
				"Create/Manage Jobs",
				"Add Pumps to Job",
				"Get Job Reports (Rate/Pressure/Totals)",
				"Generate Excel, and/or PDF"
			]}
		/>)
	},
  {
    path: "/equipment",
    layout: "/admin",
    name: "Equipment",
    icon: "pe-7s-note2",
    content: (<ToDo
			title="Equipment Management"
			list={[
				"Add/Remove/Update Equipment",
				"Detail Equipment Info",
				"Audit Log / who did what when"
			]}
		/>)
	},
  {
    path: "/settings",
    layout: "/admin",
    name: "Settings",
    icon: "pe-7s-tools",
    content: (<ToDo
			title="Settings"
			list={[
				"Any user/corp settings"
			]} />)
  },
];
export default routes;
