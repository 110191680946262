import React from "react";
import Button from "components/CustomButton/CustomButton.jsx";

import { setDeviceListUpdate } from "../../redux/dataSlice";
import { useDispatch } from "react-redux";

import { Grid, Row, Col, PanelGroup, Panel } from "react-bootstrap";

const DeviceList = ({ devices, onDeviceSelect }) => {
  const dispatch = useDispatch();

  const setOpen = (device) => {
    onDeviceSelect(device);
  };

  const getClassForConnectionType = (status) => {
    if (status === "Offline") {
      return "text-danger";
    } else if (status === "Online") {
      return "text-online";
    } else if (status === "Connected") {
      return "text-connected";
    } else {
      return "text-other";
    }
  };

  const dlist = devices.map((device) => {
    return (
      <Panel
        key={device.id}
        id={`grid-${device.id}`}
        style={{ paddingBottom: 10 }}
      >
        <Panel.Heading className={device.status.toLowerCase()}>
          <Panel.Title>
            <Grid fluid onClick={() => setOpen(device)}>
              <Row>
                <Col xs={3} sm={2} md={2}>
                  <span>{device.icon}</span>
                </Col>
                <Col xs={5} sm={6} md={6}>
                  <h5>
                    <span className={"pump-name"}>{device.name}</span> <br />
                    <span className={"device-description"}>
                      {device.description}
                    </span>
                  </h5>
                </Col>
                <Col xs={4} className="text-right">
                  <span className={getClassForConnectionType(device.status)}>
                    {device.status}
                  </span>
                  <br />
                  <small className="text-muted">
                    {device.oldLocation
                      ? "GPS from " + device.lastFix.fromNow() + ", "
                      : ""}
                    {device.location.name} <br />
                    {device.isConnected
                      ? ""
                      : device.lastContact
                      ? device.lastContact.fromNow()
                      : ""}
                  </small>{" "}
                </Col>
              </Row>
              {((device.status.toLowerCase()!="offline") && (device.type == "Tricon" || device.type == "Mustang")) ? (
                <Row className="controls">
                  <Col xs={6} sm={2}>
                    <Button
                      fill
                      style={{
                        padding: "4px 4px",
                        height: 58,
                        lineHeight: 1.2,
                        fontSize: "medium",
                        fontWeight: "bold",
                        color: "#04FFF7",
                      }}
                    >
                      {" "}
                      {/* add for mustang here */}
                      {device.isMustangRW ? device.prop_jrpm : device.prop_rpm}
                      <br /> RPM
                    </Button>
                  </Col>
                  <Col xs={6} sm={2}>
                    <Button
                      fill
                      style={{
                        padding: "4px 4px",
                        height: 58,
                        lineHeight: 1.2,
                        fontSize: "medium",
                        fontWeight: "bold",
                        color: "#04FFF7",
                      }}
                    >
                      {" "}
                      {device.isMustangRW ? device.prop_jloadPercent : device.prop_loadPercent}
                      <br /> LOAD %
                    </Button>
                  </Col>
                  <Col xs={6} sm={2}>
                    <Button
                      fill
                      style={{
                        padding: "4px 4px",
                        height: 58,
                        lineHeight: 1.2,
                        fontSize: "medium",
                        fontWeight: "bold",
                        color: "#04FFF7",
                      }}
                    >
                      {" "}
                      {device.prop_jfuelpsi}
                      <br />
                      FUEL PSI
                    </Button>
                  </Col>
                  <Col xs={6} sm={2}>
                    <Button
                      fill
                      bsStyle="success"
                      style={{
                        padding: "4px 4px",
                        height: 58,
                        lineHeight: 1.2,
                        fontSize: "medium",
                        fontWeight: "bold",
                        color: "#04FFF7",
                      }}
                    >
                      {" "}
                      {device.isMustangRW ? device.prop_jcoolant : device.prop_rate}
                      <br />
                      {device.isMustangRW ? "COOLANT TEMP" : "RATE BPM"}
                    </Button>
                  </Col>
                  <Col xs={6} sm={2}>
                    <Button
                      fill
                      bsStyle="success"
                      style={{
                        padding: "4px 4px",
                        height: 58,
                        lineHeight: 1.2,
                        fontSize: "medium",
                        fontWeight: "bold",
                        color: "#04FFF7",
                      }}
                    >
                      {" "}
                      {device.isMustangRW ? device.prop_joil : device.prop_pumpSuctionPsi}
                      <br />
                      {device.isMustangRW ? "OIL PRESSURE" : "SUCTION PSI"}
                    </Button>
                  </Col>
                  <Col xs={6} sm={2}>
                    <Button
                      fill //bsStyle="success"
                      style={{
                        padding: "4px 4px",
                        height: 58,
                        lineHeight: 1.2,
                        fontSize: "medium",
                        fontWeight: "bold",
                        color: "#04FFF7",
                      }}
                    >
                      {" "}
                      {device.isMustangRW ? device.prop_jfuelrate : device.prop_dischargePsi}
                      <br />
                      {device.isMustangRW ? "FUEL RATE" : "DISCHARGE PSI"}
                    </Button>
                  </Col>
                </Row>
              ) : ((device.status.toLowerCase()!="offline") && (device.type == "J1939")) ? (
                <Row className="controls">
                  <Col xs={6} sm={2}>
                    <Button fill>
                      {" "}
                      {device.prop_jrpm}
                      <br /> RPM
                    </Button>
                  </Col>
                  <Col xs={6} sm={2}>
                    <Button fill>
                      {" "}
                      {device.prop_jloadPercent}
                      <br /> Load %
                    </Button>
                  </Col>
                  <Col xs={6} sm={2}>
                    <Button fill>
                      {" "}
                      {device.prop_jfuelpsi}
                      <br /> Fuel PSI
                    </Button>
                  </Col>
                  <Col xs={6} sm={2}>
                    <Button fill>
                      {" "}
                      {device.prop_jcoolant}
                      <br /> Coolant °F
                    </Button>
                  </Col>
                  <Col xs={6} sm={2}>
                    <Button fill>
                      {" "}
                      {device.prop_joil}
                      <br /> Oil PSI
                    </Button>
                  </Col>
                  <Col xs={6} sm={2}>
                    <Button fill>
                      {" "}
                      {device.prop_jfuelrate}
                      <br /> Fuel Rate
                    </Button>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Grid>
          </Panel.Title>
        </Panel.Heading>
      </Panel>
    );
  });

  return <PanelGroup id="key">{dlist}</PanelGroup>;
};

export default DeviceList;
