// /*!

// =========================================================
// * Light Bootstrap Dashboard PRO React - v1.2.0
// =========================================================

//
// Refactored to be a functional component instead of a class component
//

import React, { useState, useEffect, useRef } from "react";
import { Collapse, ControlLabel, FormControl, FormGroup, Modal } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import avatar from "assets/img/default-avatar.png";
//import logo from "logo.png";
import logo from "assets/img/cclogo.png";
import routes from "routes.jsx";
import Button from "../CustomButton/CustomButton";
import { logout } from "../../models/api";

const Sidebar = ({ color, image, app }) => {

  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.forEach((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState
        };
      }
    });
    return initialState;
  };

  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        const st = { [prop["state"]]: !state[prop.state] };
        return (
          <li className={getCollapseInitialState(prop.views) ? "active" : ""} key={key}>
            <a
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                setState(st);
              }}
            >
              <i className={prop.icon} />
              <p>
                {prop.name}
                <b
                  className={
                    state[prop.state] ? "caret rotate-180" : "caret"
                  }
                />
              </p>
            </a>
            <Collapse in={this.state[prop.state]}>
              <ul className="nav">{createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            className="nav-link"
            activeClassName="active"
          >
            {prop.icon ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };


  const [state, setState] = useState({
    ...getCollapseStates(routes),
    openAvatar: false,
    isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
    width: window.innerWidth,
    username: app.user ? app.user.name : '',
    current_password: '',
    new_password: '',
    new_password2: '',
    change_error: '',
    showChangeModal: false
  });

  const location = useLocation();

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
  }, []);

  const updateDimensions = () => {
    setState(prevState => ({ ...prevState, width: window.innerWidth }));
  };

  const handleChange = evt => {
    if (evt.target.name) {
      setState(prevState => ({ ...prevState, [evt.target.name]: evt.target.name === 'remember' ? evt.target.checked : evt.target.value }));
    }
  };

  const handleChangePassword = evt => {
    evt.preventDefault();
    if(state.new_password != state.new_password2) {
      alert("New Password doesn't match");
    } else {
      app.api.try_change_password(state.current_password, state.new_password).then(function(res){
        console.log(res);
        if(res.success) {
          logout();
        } else {
          alert(res.message);
        }
      })
    }
    // ...need to do
  };

  const hideChangePassword = () => {
    setState(prevState => ({
      ...prevState,
      current_password: "",
      new_password: "",
      new_password2: "",
      change_error: '',
      showChangeModal: false
    }));
  };

  const handleLogout = evt => {
    evt.preventDefault();
    logout();
  };

  return (
    <div className="sidebar" data-color={color} data-image={image}>
      <div className="sidebar-background" style={{ backgroundImage: "url(" + image + ")" }} />
      <div className="logo">
        <a href="https://www.fracpumpcontrols.com/"
          className="simple-text logo-mini" target="_blank">
          <div className="logo-img">
            <img src={logo} alt="app-logo" />
          </div>
        </a>
        <a href="https://www.fracpumpcontrols.com/"
          className="simple-text logo-normal" target="_blank">
          Eagles Nest
        </a>
      </div>
      <div className="sidebar-wrapper">
        <ul className="nav">
          {state.width <= 992 ? <AdminNavbarLinks /> : null}
          {createLinks(routes)}
        </ul>
        <div className="user">
          {/* <div className="photo">
            <img src={avatar} alt="Avatar" />
          </div> */}
          <div className="info">
            <div>
              <a><span>{state.username}</span></a>
            </div>
          </div>
          <div>
            <a href="#" onClick={(e) => { e.preventDefault(); setState({ showChangeModal: true }); }}>Change Password</a>
          </div>
          <div>
            <a href="#" onClick={handleLogout}>Logout</a>
          </div>

        </div>
      </div>
      <Modal show={state.showChangeModal} onHide={() => hideChangePassword()}>
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
          <Modal.Body>
            <form onSubmit={handleChangePassword} method={"POST"}>
              <FormGroup>
                <ControlLabel>Current Password</ControlLabel>
                <FormControl name="current_password" placeholder="Current Password" type="password" onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>New Password</ControlLabel>
                <FormControl name="new_password" placeholder="New Password" type="password" onChange={handleChange} />
              </FormGroup>
              <FormGroup>
                <ControlLabel>New Password (repeat)</ControlLabel>
                <FormControl name="new_password2" placeholder="New Password" type="password" onChange={handleChange} />
              </FormGroup>
              {state.change_error ? (<div className={"alert alert-danger"}>{state.change_error}</div>) : ("")}
              <input type="submit" style={{ "display": "none" }} />
            </form>
          </Modal.Body>
        </Modal.Header>
        <Modal.Footer>
          <Button simple onClick={() => hideChangePassword()}>Cancel</Button>
          <Button simple onClick={handleChangePassword}>Change Password</Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
};

export default Sidebar;

