// /*!

// =========================================================
// * Light Bootstrap Dashboard PRO React - v1.2.0
// =========================================================

//
// Refactored to be a functional component instead of a class component
//

import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import NotificationSystem from "react-notification-system";

import Sidebar from "components/Sidebar/Sidebar.jsx";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

import image from "assets/img/background.png";
import routes from "routes.jsx";
import { style } from "variables/Variables.jsx";
import { useDispatch, useSelector } from "react-redux";
import { setAllDevices } from "../redux/dataSlice";
import { App, API } from "../models/api";

let ps;

const AdminLayout = ({ props }) => {
  //redux states
  const dispatch = useDispatch();
  const allDevices = useSelector((state) => state.data.allDevices);
  const deviceListUpdate = useSelector((state) => state.data.deviceListUpdate);
  const [data, setData] = useState([]);
  const [state, setState] = useState({
    _notificationSystem: null,
    image: image,
    color: "black",
    hasImage: true,
    navbar: false,
    mini: false,
    fixedClasses: "dropdown show-dropdown open",
  });

  const mainPanelRef = useRef(null);
  const notificationSystemRef = useRef(null);
  const location = useLocation();
  const fetchData = async () => {
    try {
      const dataX = await App();
      if (dataX && dataX.devices) {
        dispatch(setAllDevices(dataX.devices));
        setData(dataX);
      }
    } catch (error) {
      console.error("An error occurred in App function:", error);
    }
  };
  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      _notificationSystem: notificationSystemRef.current,
    }));

    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanelRef.current);
    }

    return () => {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (deviceListUpdate) {
      fetchData();
      const interval = setInterval(() => {
        fetchData();
      }, 30000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [deviceListUpdate]);

  useEffect(() => {
    // Handle the hash when the component mounts (for initial URL)
    handleHashChange();
    // Then set up the event listener for subsequent URL hash changes
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [dispatch]);

  useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }

    if (
      window.innerWidth < 993 &&
      location.pathname !== location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }, [location]);

  const handleHashChange = () => {
    dispatch({ type: "URL_CHANGE" });
    //WHEN THE ROUTE CHANGES SCROLL TO TOP OF PAGE
    //THIS IS MAINLY TO SOLVE THE BUG OF THE APP SCROLLING UP WHEN A DEVICE IS SELECTED
    setTimeout(() => {
      console.log("Scroll getRoutes");
      window.scrollTo({
        top: 0,
        behavior:'smooth'
      });
    }, 10);
  };

  const handleMiniClick = () => {
    setState((prevState) => ({
      ...prevState,
      mini: !prevState.mini,
    }));
    document.body.classList.toggle("sidebar-mini");
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        const api = API();
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={
              prop.content
                ? (_) => prop.content
                : (routeProps) => <prop.component allDevices={allDevices} api={api} />
            }
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div className="wrapper">
      <>
        <NotificationSystem ref={notificationSystemRef} style={style} />
        <Sidebar
          {...props}
          devices={allDevices}
          app={data}
          image={state.image}
          color={state.color}
          hasImage={state.hasImage}
          mini={state.mini}
        />
        <div className="main-panel" ref={mainPanelRef}>
          <AdminNavbar
            {...props}
            handleMiniClick={handleMiniClick}
            navbar={state.navbar}
          />
          <Switch>{getRoutes(routes)}</Switch>
          <Footer fluid />
        </div>
      </>
    </div>
  );
};

export default AdminLayout;
